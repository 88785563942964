























































import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import './scss/AboutProject.scss';
import { IAboutProject, IJuristicEntity, IJuristicMember } from '@store/modules/about-project/Interfaces';
import { AboutProjectActions, AboutProjectMutations } from '@store/modules/about-project/Types';
import { IProject, IProjectState } from '@store/modules/project/Interfaces';
import { ProjectActions } from '@store/modules/project/Types';
import {UserActionTypes} from "@store/modules/user/Types";
import {IUser} from "@store/modules/user/Interfaces";

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSAboutProject = namespace('storeAboutProject');

@Component({
    name: 'AboutProject',
    components: {
        AboutProjectHead: () => import('./components/AboutProjectHead.vue'),
        AboutProjectInfo: () => import('./components/AboutProjectInfo.vue'),
        AboutProjectMembers: () => import('./components/AboutProjectMembers.vue'),
        PopupProjectEdit: () => import('@components/popups/PopupProjectEdit.vue'),
        PopupAddProjectMember: () => import('@components/popups/PopupAddProjectMember.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    }
})

export default class AboutProject extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        membersList: HTMLFormElement,
        popupProjectEdit: HTMLFormElement,
        popupAddProjectMember: HTMLFormElement,
    }

    @NSProject.State((state: IProjectState) => state.projectLoaded) projectLoaded!: boolean;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.Action(ProjectActions.A_GET_MEMBER_ROLES) getMemberRoles!: (appId: number) => Promise<void>;
    @NSProject.Action(ProjectActions.A_GET_MEMBER_ROLES_JURISTIC) getMemberRolesJuristic!: (appId: number) => Promise<void>;
    @NSAboutProject.State((state: IAboutProject) => state.aboutProjectLoaded) aboutProjectLoaded!: boolean;
    @NSAboutProject.Getter('filters') filters!: any[];
    @NSAboutProject.Getter('filtersApply') filtersApply!: boolean;
    @NSAboutProject.Getter('juristicMembers') juristicMembers!: IJuristicMember[];
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];
    @NSAboutProject.Action(AboutProjectActions.A_GET_JURISTIC_ENTITIES) getJuristicEntities!: (id: string | number) => Promise<any> | any;
    @NSAboutProject.Action(AboutProjectActions.A_SET_JURISTIC_ENTITIES) setJuristicEntities!: (juristicEntities: IJuristicEntity[]) => void;
    @NSAboutProject.Action(AboutProjectActions.A_GET_INDIVIDUAL_MEMBERS_ABOUT_PROJECT) getIndividualMembers!: (id: string | number) => Promise<void>;
    @NSAboutProject.Action(AboutProjectActions.A_GET_JURISTIC_MEMBERS_ABOUT_PROJECT) getJuristicMembers!: (id: string | number) => Promise<void>;
    @NSAboutProject.Mutation(AboutProjectMutations.M_SET_ABOUT_PROJECT_LOADED) setAboutProjectLoaded!: (val: boolean) => void;
    @NSUser.Action(UserActionTypes.A_REFRESH_TOKEN) refreshToken!: () => Promise<IUser>

    @NSProject.Action(ProjectActions.A_GET_PROJECT) reloadProject!: (any) => Promise<void>

    views: any[] = [
        {
            label: 'Сведения',
            value: 'info',
        }, {
            label: 'Участники',
            value: 'members',
        },
    ];
    activeView: string = 'info';
    filteredIndividualMembersList: any = null;
    filteredJuristicMembersList: any = null;
    selectedMembers: any[] = [];
    informationPopupShow: boolean = false;
    informationPopupText: string = '';
    showInformationPopupDuration: number = 2000;
    changedRow: any = {};

    mounted() {
        if (this.projectLoaded) {
            this.requestAfterLoadedProject(true);
        }
    }

    @Watch('projectLoaded')
    onProjectLoaded(): void {
        if (this.projectLoaded) {
            this.requestAfterLoadedProject(true);
        }
    }

    @Watch('filters')
    watchFilters() {
        this.filteredIndividualMembersList = this.changeFilteredIndividualMembersList();
        this.filteredJuristicMembersList = this.changeFilteredJuristicMembersList();
    }

    get isFiltersForIndividualItems(): boolean {
        return this.filters
            && !!this.filters.length
            && this.filters.some((filter) => {
                return filter.block !== 'roleJuristic';
            });
    }

    get isFiltersForJuristicItems(): boolean {
        return this.filters
            && !!this.filters.length
            && this.filters.some((filter) => {
                return filter.block === 'roleJuristic' || filter.block === 'companies';
            });
    }

    get individualItems() {
        return this.filters && this.filters.length && this.filtersApply
            ? this.isFiltersForIndividualItems
                ? this.filteredIndividualMembersList
                : []
            : this.individualMembersList;
    }

    get juristicItems() {
        return this.filters && this.filters.length && this.filtersApply
            ? this.isFiltersForJuristicItems
                ? this.filteredJuristicMembersList
                : []
            : this.juristicMembersList;
    }

    get individualItemsCount(): number {
        return this.filters && this.filters?.length
            ? this.isFiltersForIndividualItems
                ? this.filteredIndividualMembersList?.length
                : [].length
            : this.individualMembersList?.length;
    }

    get juristicItemsCount(): number {
        return this.filters && this.filters?.length
            ? this.isFiltersForJuristicItems
                ? this.filteredJuristicMembersList?.length
                : [].length
            : this.juristicMembersList?.length;
    }

    get filteredCount() {
        return this.individualItemsCount + this.juristicItemsCount;
    }

    get individualMembersList(): any[] {
        const output: any[] = [];
        this.juristicEntities.forEach((juristicPerson, juristicPersonIndex) => {
            const juristicPersonId = juristicPerson.id;
            juristicPerson.responsiblePersons.forEach((responsiblePerson, responsiblePersonIndex) => {
                let addKey = `${responsiblePerson.id}-${juristicPersonIndex}-${responsiblePersonIndex}`;
                // Далее просто добавляем доп. ключ чтобы различать объекты с одинаковыми полями и id
                const boosterJuristic = juristicPersonIndex % 3 === 0 ? 3 : juristicPersonIndex % 2 === 0 ? 2 : 1;
                const boosterPrivate = responsiblePersonIndex % 3 === 0 ? 3 : responsiblePersonIndex % 2 === 0 ? 2 : 1;
                const customKey = `${addKey}-${boosterJuristic}-${boosterPrivate}`;
                const objectForOutput = {
                    ...responsiblePerson,
                    juristicPersonId: juristicPersonId,
                    customKey: customKey,
                    selected: this.selectedMembers.filter(item => item.customKey === customKey).length,
                }
                const outputFiltered = output.filter(
                    item => item.id === objectForOutput.id,
                    item => item.position === objectForOutput.position,
                );
                const outputFilteredRolePerson = outputFiltered.filter(
                    item => item.role.id === objectForOutput.role.id
                );
                const outputFilteredJuristicPerson = outputFilteredRolePerson.filter(
                    item => item.juristicPersonId === objectForOutput.juristicPersonId
                );
                if (!outputFilteredJuristicPerson.length && !outputFilteredJuristicPerson.filter(
                    item => item.activationDate === objectForOutput.activationDate,
                    item => item.deactivationDate === objectForOutput.deactivationDate
                ).length) {
                    if ((this.changedRow
                        && this.changedRow.id === objectForOutput.id
                        && objectForOutput.juristicPersonId
                        && objectForOutput.juristicPersonId === this.changedRow.juristicPersonId)
                        || (this.changedRow
                        && this.changedRow.id === objectForOutput.id
                        && !objectForOutput.juristicPersonId)) {
                        output.push(this.changedRow);
                    } else {
                        output.push(objectForOutput);
                    }
                }
            })
        });
        return output.sort(this.sortByName);
    }

    get juristicMembersList() {
        const output: any[] = [];
        this.juristicMembers.forEach((item) => {
            if (this.changedRow && this.changedRow.id === item.id) {
                output.push(this.changedRow);
            } else if (item.role) {
                let newItem = JSON.parse(JSON.stringify(item));
                output.push(newItem);
            }
        })
        return output;
    }

    get projectId() {
        return this.projectData?.id || '';
    }

    async requestAfterLoadedProject(tryRefreshToken = false): Promise<void> {
        await Promise.all([
            this.getIndividualMembers(this.projectData.id),
            this.getJuristicMembers(this.projectData.id),
            this.getMemberRoles(this.projectData.id),
            this.getMemberRolesJuristic(this.projectData.id),
            this.getJuristicEntities(this.projectData.id)
                .then((entities: IJuristicEntity[]) => this.setJuristicEntities(entities))
        ]).catch((err) => {
            if (tryRefreshToken && err.response?.status === 401) {
                this.refreshToken().then(() => {
                    this.requestAfterLoadedProject();
                });
            }
        });
    }

    changeFilteredIndividualMembersList() {
        if (this.filters && this.filters.length) {
            let output: any = this.individualMembersList;
            this.filters.forEach((filterObject) => {
                if (filterObject.type === 'checkboxes' || filterObject.type === 'select') {
                    if (filterObject.block === 'companies' && filterObject.key === 'name' && filterObject.selected.length) {
                        let added: string[] = [];
                        this.juristicMembersList.forEach((company) => {
                            if (company.name && filterObject.selected.includes(company.name) && !added.includes(company.id)) {
                                added.push(company.id);
                            }
                        })
                        output = output.filter(item => added.includes(item.juristicPersonId));
                    }
                    if (filterObject.block === 'name' && filterObject.key === 'name' && filterObject.selected.length) {
                        output = output.filter(item => filterObject.selected.includes(item.name));
                    }
                    if (filterObject.key === 'deactivationDate' && filterObject.selected.length > 1) {
                        output = [];
                    } else if (filterObject.key === 'deactivationDate' && filterObject.selected.includes('Активен')) {
                        output = output.filter(item => !item.deactivationDate);
                    } else if (filterObject.key === 'deactivationDate' && filterObject.selected.includes('Неактивен')) {
                        output = output.filter(item => item.deactivationDate);
                    }
                    if (filterObject.block === 'roleIndividual' && filterObject.key === 'role.name' && filterObject.selected.length) {
                        output = output.filter(item => item.role && filterObject.selected.includes(item.role.name));
                    }
                }
            });
            return output;
        }
        return this.individualMembersList;
    }

    changeFilteredJuristicMembersList() {
        if (this.filters && this.filters.length) {
            let output: any = this.juristicMembersList;
            this.filters.forEach((filterObject) => {
                if (filterObject.type === 'checkboxes' || filterObject.type === 'select') {
                    if (filterObject.block === 'companies' && filterObject.key === 'name' && filterObject.selected.length) {
                        output = output.filter(item => filterObject.selected.includes(item.name));
                    }
                    if (filterObject.block === 'roleJuristic' && filterObject.key === 'role.name' && filterObject.selected.length) {
                        output = output.filter(item => item.role && filterObject.selected.includes(item.role.name));
                    }
                }
            });
            return output;
        }
        return this.juristicMembersList;
    }

    sortByName(firstItem, secondItem) {
        if (firstItem.name < secondItem.name) {
            return -1;
        }
        if (firstItem.name > secondItem.name) {
            return 1;
        }
        return 0;
    }

    changeActiveView(e) {
        this.activeView = e.value;
    }

    openPopupProjectEdit() {
        this.$refs.popupProjectEdit.openPopup();
    }

    openPopupAddMember() {
        this.$refs.popupAddProjectMember.openPopup();
    }

    onSelectMember(e) {
        if (this.selectedMembers.length) {
            const newArray: any[] = [];
            let present = false;
            this.selectedMembers.forEach((item) => {
                if (item.customKey === e.customKey) {
                    present = true;
                } else {
                    newArray.push(item);
                }
            });
            if (present) {
                this.selectedMembers = newArray;
            } else {
                this.selectedMembers.push(e);
            }
        } else {
            this.selectedMembers.push(e)
        }
    }

    activationSelectedItem(val) {
        for (let i = 0; i < this.selectedMembers.length; i++) {
            setTimeout(() => {
                this.$refs.membersList.onActivate(this.selectedMembers[i], val, false);
            }, i * 500);
        }
    }

    onConfirmEditPerson(type) {
        this.reloadStateAndShowInfo(
            type,
            'Сведения о юридическом лице изменены',
            'Сведения о физическом лице изменены',
        );
    }

    onConfirmAddPerson(type) {
        this.reloadStateAndShowInfo(
            type,
            'Добавлено юридическое лицо',
            'Добавлено физическое лицо',
        );
    }

    reloadStateAndShowInfo(type: string, responsibleText: string, juristicText: string) {
        this.reloadProject({
            projectId: this.projectId,
            reloadDocuments: true,
            reloadProjectTeam: true
        })
            .then(() => {
                if (type === 'juristic') {
                    this.getJuristicMembers(this.projectData.id)
                        .then(() => {
                            this.showInformationPopup(responsibleText);
                        });
                }
                else {
                    this.showInformationPopup(juristicText);
                }
                this.setAboutProjectLoaded(false);
                this.getJuristicEntities(this.projectData.id)
                    .then((entities: IJuristicEntity[]) => {
                        this.setJuristicEntities(entities);
                        if (type === 'juristic') {
                            this.setAboutProjectLoaded(true);
                        }
                        else {
                            this.getIndividualMembers(this.projectData.id).then(() => {
                                this.setAboutProjectLoaded(true);
                            });
                        }
                    });
            });
    }

    showInformationPopup(text) {
        if (this.$refs.membersList) {
            this.$refs.membersList.closeAndReset();
        }

        if (this.$refs.popupAddProjectMember) {
            this.$refs.popupAddProjectMember.closePopup();
        }

        this.informationPopupShow = true;
        this.informationPopupText = text;

        setTimeout(() => {
            this.informationPopupShow = false;
            this.informationPopupText = '';
        }, this.showInformationPopupDuration);
    }
}
